import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Link as InternalLink, Events } from 'react-scroll'

const Container = styled.div`
  background-color: #f3f3f3;
  border: 1px solid silver;
  border-radius: 5px;
  padding: ${(props) => (
    props.isOnSidebar === true ? '10px 15px 10px 0' : '15px 15px'
  )};

  ${(props) => (
    props.isOnSidebar === true ?
    'position: sticky; top: 50px; overflow: scroll; max-height: calc(100vh - 60px);' : ''
  )}

  ${media.lessThan('small')`
    ${(props) => (
      props.isOnSidebar === true &&
      'display: none;'
    )}
  `}

  ${media.greaterThan('medium')`
    ${(props) => (
      props.isOnSidebar !== true &&
      'display: none;'
    )}
  `}

  & a {
    color: #505050;
    cursor: pointer;
    font-size: ${(props) => (
      props.isOnSidebar === true ? '0.9rem' : '1rem'
    )};
    text-decoration: none;
  }

  & a:hover {
    color: #18d3b1;
  }
`

const TableTitle = styled.p`
  font-size: 1.1rem;
  margin: 5px 15px 10px;
`

const LinkWrapper = styled.div`
  padding-left: ${(props) => (
    `${(props.tag - props.highestHeadingNumber) * 20 + 20}px`
  )};
  margin: ${(props) => (
    props.tag === `${props.highestHeadingNumber}` ? '7px 0 3px' : '0'
  )};
  font-weight: ${(props) => (
    props.tag === `${props.highestHeadingNumber}` ? 'bold' : 'normal'
  )};
`

const StyledInternalLink = styled(InternalLink)`
  color: ${(props) => (
    props.$active ? '#18d3b1 !important' : 'unset'
  )};
`

const TableOfContents = ({ data, isOnSidebar }) => {
  const [isScrollingTo, setIsScrollingTo] = useState(null)
  // get highest heading's number
  let highestHeadingNumber = 3
  data.forEach((obj) => {
    if (Number(obj.tag) < highestHeadingNumber) {
      highestHeadingNumber = Number(obj.tag)
    }
  })

  Events.scrollEvent.register('begin', (to) => {
    setIsScrollingTo(to)
  })

  Events.scrollEvent.register('end', () => {
    setIsScrollingTo(null)
  })

  const updateHistory = (id) => {
    if (typeof window !== 'undefined') {
      window.history.pushState(null, null, `#${id}`)
    }
  }

  return (
    <Container isOnSidebar={isOnSidebar}>
      <TableTitle>目次</TableTitle>
      {data.map((link) => {
        let isActiveLink = false
        if (isScrollingTo !== null) {
          isActiveLink = isScrollingTo === link.id
        } else {
          isActiveLink = link.active
        }
        return (
          <LinkWrapper
            key={link.id}
            tag={link.tag}
            highestHeadingNumber={highestHeadingNumber}
          >
            <StyledInternalLink
              to={link.id}
              smooth
              duration={500}
              // offset={-50} // original
              offset={-30}
              tag={link.tag}
              data-display={link.text}
              data-active={link.active}
              $active={isActiveLink}
              onClick={() => updateHistory(link.id)}
            >
              { link.text }
            </StyledInternalLink>
          </LinkWrapper>
        )}
      )}
    </Container>
  )
}

export default TableOfContents
